// Title medium bold

import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const Heading2 = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 44px;
  line-height: 64px;
  font-weight: 600;
  letter-spacing: 0.5px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export default Heading2;
