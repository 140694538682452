import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const Blockquote = styled.h3`
  color: ${COLORS.ORANGE};
  font-family: Adonis !important;
  font-size: 40px;
  font-weight: 400;
  font-style: italic;
  line-height: 52px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 32px;
    line-height: 28px;
  }
`;

export default Blockquote;
