import styled from "@emotion/styled";
import { COLORS, BREAKPOINTS } from "./variables";

const ParagraphExLarge = styled.p`
  color: ${COLORS.BLACK};
  font-size: 24px;
  line-height: 33px;
  font-weight: 400;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export default ParagraphExLarge;
